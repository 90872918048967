<template>
  <div class="mt-4 pl-4 pr-4">
    <vs-tabs color="warning">
      <vs-tab label="Resumo da Corretora">
        <vs-card class="mt-4 p-8 dados-gerais">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta" v-if="exibirJustificativaMovimentacaoCard"> 
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="text-sm font-semibold">
                Justificativa para movimentação do card <span v-if="justificativaMovimentacaoCardObrigatoria">(obrigatório)</span>:
              </p>
              <vs-textarea 
                class="mt-4" 
                v-model="justification" 
                :disabled="!podeModificarJustificativaMovimentacaoCard"
              />
            </div>
            <div class="vx-col w-full text-right">
              <vs-button
                :disabled="!podeModificarJustificativaMovimentacaoCard"
                @click.native="salvarJustificativa()"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Salvar
                </span>
              </vs-button>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-gerais" >
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data do cadastro</p>
              <p class="text-lg">{{ corretoraCreateDate || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-gerais" >
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Razão Social</p>
              <p class="text-lg">{{ corretora.Name|| "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">CNPJ Corretora</p>
              <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="corretora.CpfCnpj"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/2 mt-2 mb-6 ">
              <p class="text-sm font-semibold">Situação</p>
              <p class="text-lg">{{ corretora.SUSEPStatus|| "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-gerais" >
          <div class="vx-row">
            <div class="vx-col w-1/1">
              <p class="text-sm font-semibold">Endereço</p>
              <p class="text-lg">{{ corretora.LocationAddress|| "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/1 mt-4">
              <p class="text-sm font-semibold">Telefone de contato</p>
              <the-mask
              v-if="corretora.PhoneNumber"
                class="text-lg invisible-input"
                :mask="['(##) ####-####', '(##) #####-####']"
                disabled="true"
                :masked="true"
                :value="corretora.PhoneNumber || 'Não informado'"
              />
              <p class="text-lg" v-else> Não informado </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-gerais" >
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Nome Responsável Tecnico - SUSEP</p>
              <p class="text-lg">{{ corretora.NameSusep || "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Recadastrado</p>
              <p class="text-lg">{{ corretoraRecadastrado || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-gerais" >
          <div class="vx-row">
            <div class="vx-col w-1/1">
              <p class="text-sm font-semibold">Produtos</p>
              <p class="text-lg">{{ corretora.SUSEPProducts || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <div class="dados-gerais p-4 pt-2">
          <div class="vx-row mt-5">
            <div class="vx-col md:w-12/12 w-full md:mt-0 mt-5">
              <TableSocios
                class="kaban"
                v-if="currentPipe.name === 'PEP'"
                :Socios="data.UniqueId"
                :TypeText="'CORRETORA_PEP'"
              />
            </div>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Histórico do Motor">
        <div
          class="vx-row"
          v-if="
            !(corretora || {}).BrokerExecutionLogs ||
              (corretora || {}).BrokerExecutionLogs.length == 0
          "
        >
          <div class="vx-col w-full mt-2">
            <vs-alert color="warning" class="h-auto">
              Nenhuma regra foi executada nessa proposta.
            </vs-alert>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col pt-5 w-full mt-2">
            <vs-collapse
              type="margin"
              v-for="(regra, index) in (corretora || {}).BrokerExecutionLogs"
              :key="index"
            >
              <vs-collapse-item>
                <div slot="header">
                  <p class="text-sm font-semibold"></p>
                  <p class="text-lg">
                    {{
                      (regra || {}).EngineExecutionType.Name || "Não informado"
                    }}
                  </p>
                  <p class="text-sm">
                    <b>Executada em </b
                    >{{ (regra || {}).CreateDate | moment("DD/MM/YYYY") }}
                  </p>
                  <!--<vs-button class="status" type="filled"
                          size="small"
                          :color="getLabelColor(regra)">
                          {{getLabelText(regra)}}
                        </vs-button>-->
                </div>

                <div class="vx-row flex">
                  <div class="vx-col flex-1">
                    <vs-table
                      :data="
                        ((regra || {}).RuleEngineResult || {}).RuleResults || []
                      "
                      noDataText="Nenhum histórico de regras executado."
                    >
                      <template slot="thead">
                        <vs-th> Etapa </vs-th>
                        <vs-th> Regra </vs-th>
                        <vs-th> Executada com sucesso? </vs-th>
                        <vs-th> Mensagem </vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                          :style="getRowStyle(data[indextr])"
                        >
                          <vs-td :data="indextr">
                            <div class="flex">
                              <span>{{ indextr + 1 }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].RuleName">
                            <div class="flex">
                              <span>{{ data[indextr].RuleName }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].Success">
                            <div class="flex">
                              <span>{{
                                data[indextr].Success ? "Sim" : "Não"
                              }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].Messages">
                            <div class="flex">
                              <span
                                v-if="
                                  (data[indextr].Messages || []).length == 0
                                "
                              ></span>
                              <div
                                v-if="(data[indextr].Messages || []).length > 0"
                              >
                                <ul>
                                  <li
                                    v-for="(message, indexMsg) in data[indextr]
                                      .Messages || []"
                                    :key="indexMsg"
                                  >
                                    {{ message }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
import { mask, TheMask } from "vue-the-mask";
import TableSocios from "@/components/vs-table/Table-Socios.vue";
import { kanbanProvider } from "@/providers/kanban.provider";
import environment from "../../../environment";
export default {
  name: "brokerDataViewContent",
  directives: { mask },
  components: { TheMask, TableSocios },
  data() {
    return {
      Newe: false,
      selectedOwnerCard: null,
      priorityCard: null,
      selectedPriorityId: null,
      justification: null
    };
  },
  props: {
    data: {
      type: Object
    },
    currentPipe: { type: Object },
    cardId: {
      type: String
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    cardInfos: {
      type: Object,
      default: () => {}
    },
    justificationParameters: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    corretoraRecadastrado: {
      get() {
        if (
          !this.corretora.SUSEPReregister ||
          this.corretora.SUSEPReregister == "False"
        )
          return "Não";
        else return "Sim";
      }
    },
    corretoraCreateDate: {
      get() {
        return this.$moment(this.corretora.CreateDate).format("DD/MM/YYYY");
      }
    },
    corretora: {
      get() {
        return this.data || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    carId: {
      get() {
        return this.cardId || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },

    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    },

    justificativaMovimentacaoCard() {
      const item = this.cardInfos.cardData.body.metas.find((item) => item.description == "Justificativa");
      return item ? item.value : "";
    },

    justificativaMovimentacaoCardObrigatoria() {
      return this.justificationParameters.IsCardJustificationRequired;
    },

    exibirJustificativaMovimentacaoCard() {
      return this.justificationParameters.CanAddCardJustification;
    },

    podeModificarJustificativaMovimentacaoCard() {
      return this.justificationParameters.StepAllowedToInsertJustification;
    }
  },
  watch: {
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    },
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    justificativaMovimentacaoCard(newValue){
      this.justification = newValue
    }
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }

    this.justification = this.justificativaMovimentacaoCard;
  },
  methods: {
    getLabelText(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "APROVADO";
      else return "REPROVADO";
    },

    getLabelColor(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "success";
      else return "danger";
    },

    getRowStyle(data) {
      if (data.Success) return { backgroundColor: "#97db9e" };
      else return { backgroundColor: "#e69191" };
    },

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },

    async salvarJustificativa() {
      if (this.justification != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.UpdateKanbanCardJustification({
            cardId: this.cardId,
            Justification: this.justification
          })
          .then(data => {
            this.$vs.notify({
              title: "Sucesso",
              text:
                "Dados salvos com sucesso!",
              color: "success"
            });
            this.$emit("refreshPipe");
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    }
  }
};
</script>
<style lang="scss">
.invisible-input{
  background-color: transparent;
  border: none;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
}
</style>
